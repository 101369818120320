import JshModule from '@core/scripts/helper/jsh-module';
import {handleEntryStylesProductSlider, handleEntryStylesRecommendation} from './teaser-entry-cookie-functions.js';

const {moduleScope} = JshModule('teaser-entry-cookie');
/**
 * Zwei verschiedene Teaser Entry Cookies werden hier verarbeitet:
 * 1. Teaser Entry Slider (Cookie Name: teaserEntrySlider)
 *   -> Ein Produktkarussell wird auf der ADA angezeigt.
 *   Beispiel des Cookie-Inhalts:
 *   `shop:178676:93781396:Meine Karussell-Headline|order_item:936040:936040:|order_item:936040:936040:|order_item:948240:948240:|order_item:948240:948240:|`
 *   Format des Cookie-Inhalts:
 *   `[type]:[target]:[reference]:[headline]`
 *   type:string ist entweder `order_item`, `url`, `channel`, `shop`
 *   target:string
 *   reference:int (bei Typ order_item und shop, die orderNumber)
 *   headline:string (was für einen Titel soll das Karussel bekommen?)
 * 2. Teaser Entry Recommendation (Cookie Name: entrystyles)
 *   -> Das gewählte Produkt wird hochgerankt.
 *   Beispiele des Cookie-Inhalts:
 *   `5906:13437018;`
 *   `5906:34818231,12910131,39103813;`
 *   Format des Cookie-Inhalts:
 *   `[shopId]:[masterStyleId_1],[masterStyleId_2],[masterStyleId_n];`
 */

const teaserEntryCookieNodes = moduleScope?.querySelectorAll('[jsh-teaser-entry-cookie]');

teaserEntryCookieNodes?.forEach((node) => {
    node.addEventListener('click', handleEntryStyles);
});

function handleEntryStyles(event) {
    const node = event.currentTarget;
    const config = node.getAttribute('jsh-teaser-entry-cookie');
    handleEntryStylesRecommendation(config);
    handleEntryStylesProductSlider(config);
}
